<script setup lang="ts">
import { getTranslatedProperty } from "@shopware/helpers";

const { apiClient } = useShopwareContext();
const { data: paymentMethods } = await useAsyncData(
  "payment-methods",
  async () => {
    const { data: response } = await apiClient.invoke(
      "readPaymentMethod post /payment-method",
      { body: { onlyAvailable: false } },
    );

    return response?.elements || [];
  },
);
</script>
<template>
  <div class="flex flex-wrap items-center gap-3">
    <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
      <SharedMediaImage
        v-if="paymentMethod?.media"
        :media="paymentMethod.media"
        :height="30"
        class="flex items-center justify-center font-700 h-[30px]"
        :alt="getTranslatedProperty(paymentMethod, 'name')"
        :title="getTranslatedProperty(paymentMethod, 'name')"
      />
      <span
        v-else
        class="flex items-center justify-center font-700 h-[30px] c-black bg-white rd px-1"
      >
        {{ getTranslatedProperty(paymentMethod, "name") }}
      </span>
    </div>
  </div>
</template>
